import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { css } from '@emotion/core';
import styles from '../../../styles';
import CardIcon from '../../../components/Card/CardIcon';
import expertIcon from '../../../assets/images/general/expert.svg';
import communityIcon from '../../../assets/images/general/community.svg';

const messages = defineMessages({
  consumersHeading: {
    id: 'helpPage.consumers.heading',
    defaultMessage: 'Consumers'
  },
  consumersText: {
    id: 'helpPage.consumers.text',
    defaultMessage:
      "Learn how to access PolySwarm's threat intelligence using the PolySwarm UI, API library, and command line tool."
  },
  consumersAlt: {
    id: 'helpPage.consumers.alt',
    defaultMessage: 'Consumers'
  },
  suppliersHeading: {
    id: 'helpPage.suppliers.heading',
    defaultMessage: 'Suppliers'
  },
  suppliersText: {
    id: 'helpPage.suppliers.text',
    defaultMessage:
      'Learn about the PolySwarm marketplace, including how to build and operate engines and arbiters.'
  },
  suppliersAlt: {
    id: 'helpPage.suppliers.alt',
    defaultMessage: 'Suppliers'
  }
});

const cards = [
  {
    key: 1,
    to: '/consumers',
    heading: messages.consumersHeading,
    text: messages.consumersText,
    icon: {
      src: communityIcon,
      alt: messages.consumersAlt
    }
  },
  {
    key: 2,
    to: '/suppliers',
    heading: messages.suppliersHeading,
    text: messages.suppliersText,
    icon: {
      src: expertIcon,
      alt: messages.suppliersAlt
    }
  }
];

const HelpHero = () => (
  <div css={style.root}>
    <div css={style.content}>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='h2 h-mb-md'>
              <FormattedMessage id='helpPage.heading' defaultMessage='Hello! How can we help?' />
            </h1>
          </div>
          {cards.map((card, index) => (
            <div
              key={card.key}
              className={`col-md-4 ${index === 0 ? 'offset-md-2' : ''}`}
              css={style.card}>
              <CardIcon
                to={card.to}
                renderIcon={() => <img src={card.icon.src} alt={card.icon.alt} />}
                renderHeading={() => <FormattedMessage {...card.heading} />}
                renderText={() => <FormattedMessage {...card.text} />}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const style = {
  root: css`
    background-color: ${styles.color.offWhite};
    text-align: center;
    padding: ${styles.spacing.lg} 0;
    @media (min-width: ${styles.breakpoint.md}) {
      padding: ${styles.spacing.xl} 0;
    }
  `,
  content: css`
    max-width: 50rem;
    margin: 0 auto;
    @media (min-width: ${styles.breakpoint.md}) {
      max-width: 160rem;
    }
  `,
  card: css`
    margin-bottom: ${styles.spacing.grid};
    &:last-child {
      margin-bottom: 0;
    }
    @media (min-width: ${styles.breakpoint.md}) {
      margin-bottom: 0;
    }
  `
};

export default HelpHero;
