import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styles from '../../styles';

const CallToAction = ({ renderIcon, renderHeading, renderText, renderButton }) => (
  <div css={style.root}>
    <span css={style.icon}>{renderIcon()}</span>
    <h3 className='h3' css={style.heading}>
      {renderHeading()}
    </h3>
    <p className='p' css={style.text}>
      {renderText()}
    </p>
    <div>{renderButton()}</div>
  </div>
);

CallToAction.propTypes = {
  renderIcon: PropTypes.func.isRequired,
  renderHeading: PropTypes.func.isRequired,
  renderText: PropTypes.func.isRequired,
  renderButton: PropTypes.func.isRequired
};

const style = {
  root: css`
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  `,
  icon: css`
    img {
      max-width: 12rem;
      @media (min-width: ${styles.breakpoint.sm}) {
        max-width: 14rem;
      }
    }
  `,
  heading: css`
    max-width: 32rem;
    margin: ${styles.spacing.xxs} auto ${styles.spacing.tiny};
    @media (min-width: ${styles.breakpoint.sm}) {
      max-width: 48rem;
      margin: ${styles.spacing.xxs} auto;
    }
  `,
  text: css`
    max-width: 32rem;
    margin: 0 auto ${styles.spacing.xs};
    @media (min-width: ${styles.breakpoint.sm}) {
      max-width: 48rem;
      margin: 0 auto ${styles.spacing.sm};
    }
  `
};

export default CallToAction;
