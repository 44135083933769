import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styles from '../../../styles';
import Card from '../';
import { ButtonLink } from '../../../components/Button';

const CardIcon = ({ to, renderIcon, renderHeading, renderText }) => (
  <ButtonLink css={style.root} to={to}>
    <Card css={style.card}>
      <span css={style.icon}>{renderIcon()}</span>
      <h2 className='h5' css={style.heading}>
        {renderHeading()}
      </h2>
      <p css={style.text}>{renderText()}</p>
    </Card>
  </ButtonLink>
);

CardIcon.propTypes = {
  to: PropTypes.string.isRequired,
  renderIcon: PropTypes.func.isRequired,
  renderHeading: PropTypes.func.isRequired,
  renderText: PropTypes.func.isRequired
};

const style = {
  root: css`
    text-decoration: none;
    display: block;
    height: 100%;
  `,
  card: css`
    text-align: center;
    padding: 3rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: box-shadow ${styles.easing.time} ${styles.easing.main};
    &:hover {
      box-shadow: ${styles.shadow.default};
    }
  `,
  icon: css`
    display: block;
    margin-bottom: ${styles.spacing.tiny};
    img {
      max-width: 6.8rem;
    }
  `,
  heading: css`
    margin-bottom: 0.4rem;
    color: ${styles.color.black};
  `,
  text: css`
    color: ${styles.color.xLightGrey};
  `
};

export default CardIcon;
