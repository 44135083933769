import React from 'react';
import { defineMessages } from 'react-intl';
import BaseLayout from '../../components/layout/BaseLayout';
import SEO from '../../components/SEO';
import HelpHero from './HelpHero';
import HelpCallToAction from './HelpCallToAction';

const messages = defineMessages({
  title: {
    id: 'helpPage.title',
    defaultMessage: 'Help'
  }
});

const HelpPageTemplate = ({ pageContext: { locale }, location }) => (
  <BaseLayout
    locale={locale}
    render={({ intl }) => (
      <>
        <SEO
          title={intl.formatMessage(messages.title)}
          locale={locale}
          pathname={location.pathname}
        />
        <HelpHero />
        <HelpCallToAction />
      </>
    )}
  />
);

export default HelpPageTemplate;
