import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { css } from '@emotion/core';
import styles from '../../../styles';
import CallToAction from '../.././../components/CallToAction';
import { ButtonLink } from '../../../components/Button';
import engineIcon from '../../../assets/images/general/microengine.svg';
import faqIcon from '../../../assets/images/general/faq.svg';

const messages = defineMessages({
  engineAlt: {
    id: 'helpCta.engine.alt',
    defaultMessage: 'engine',
  },
  engineHeading: {
    id: 'helpCta.engine.heading',
    defaultMessage: 'Read how to build an engine',
  },
  engineText: {
    id: 'helpCta.engine.text',
    defaultMessage:
      'This tutorial will step you through building your very first PolySwarm engine.',
  },
  engineButton: {
    id: 'helpCta.engine.button',
    defaultMessage: 'Read Tutorial',
  },
  faqAlt: {
    id: 'helpCta.cli.alt',
    defaultMessage: 'FAQ',
  },
  cliHeading: {
    id: 'helpCta.cli.heading',
    defaultMessage: 'Use PolySwarm in your scripts & terminal',
  },
  cliText: {
    id: 'helpCta.cli.text',
    defaultMessage:
      'Learn how to easily get threat intelligence using the PolySwarm command line tool.',
  },
  cliButton: {
    id: 'helpCta.cli.button',
    defaultMessage: 'Read CLI Docs',
  },
});

const ctas = [
  {
    key: 1,
    icon: {
      src: faqIcon,
      alt: messages.faqAlt,
    },
    heading: messages.cliHeading,
    text: messages.cliText,
    button: {
      to: '/consumers/polyswarm-customer-cli-v3',
      label: messages.cliButton,
    },
  },
  {
    key: 2,
    icon: {
      src: engineIcon,
      alt: messages.engineAlt,
    },
    heading: messages.engineHeading,
    text: messages.engineText,
    button: {
      to: '/suppliers/roles-in-the-marketplace#engines-and-webhooks',
      label: messages.engineButton,
    },
  },
];

const HelpCallToAction = () => (
  <div className='container'>
    <div className='row'>
      {ctas.map((cta) => (
        <div key={cta.key} className='col-md-6' css={style.cta}>
          <CallToAction
            renderIcon={() => <img src={cta.icon.src} alt={cta.icon.alt} />}
            renderHeading={() => <FormattedMessage {...cta.heading} />}
            renderText={() => <FormattedMessage {...cta.text} />}
            renderButton={() => (
              <ButtonLink variant='large' to={cta.button.to}>
                <FormattedMessage {...cta.button.label} />
              </ButtonLink>
            )}
          />
        </div>
      ))}
    </div>
  </div>
);

const style = {
  cta: css`
    padding: ${styles.spacing.lg} ${styles.spacing.sm};
    &:first-of-type {
      border-bottom: 1px solid ${styles.border.color.grey};
    }
    @media (min-width: ${styles.breakpoint.md}) {
      padding: ${styles.spacing.xl} ${styles.spacing.sm};
      &:first-of-type {
        border-right: 1px solid ${styles.border.color.grey};
        border-bottom: none;
      }
    }
  `,
};

export default HelpCallToAction;
